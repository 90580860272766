import styled from 'styled-components';
import Background from '../../assets/img/backgroundThankspage.svg';
import { defaultTheme } from '../../styles/theme';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4% 0 1% 0;

  @media (max-width: 540px) {
    padding: 4% 0 3% 0;
  }
`;

export const Logo = styled.img`
  width: 13.75rem;
  filter: brightness(0) invert();
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Roboto';

  h4 {
    font-size: 2.5rem;
    font-weight: 500;
    color: ${defaultTheme.colors.white};
    padding-bottom: 1.875rem;
  }

  p {
    color: ${defaultTheme.colors.white};
  }

  p:last-child {
    padding-top: 0.625rem;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  a + a {
    margin-left: 2.5rem;
  }
`;
